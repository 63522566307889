import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { ActivityIndicator } from '../components/generic'
import { logout } from '../services/auth'

function LogoutPage() {
  useEffect(() => {
    const redirect = new URLSearchParams(window.location.search).get('redirect')
    logout(() => navigate(redirect || '/'))
  }, [])

  return <ActivityIndicator />
}

export default LogoutPage
